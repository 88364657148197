import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { giftcardList,purchasedGiftCardList, giftcardAdd, giftcardUpdate, giftcardDelete, giftwalletAdd, giftwalletUpdate } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "giftcard";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    giftcardDatas: null,
    purchasedGiftcardDatas: null,
    giftcardCount: 0,
    purchasedGiftcardCount: 0,

};

export const giftcardData = createAsyncThunk(
    `${namespace}/giftcardData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order=""  }, { rejectWithValue, dispatch }) => {
        try {
            console.log("TransactionList");
           let  postData = {payload : {query, page, limit, sortby, order}}
            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await giftcardList( postData);
            console.log("TransactionList", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const purchasedGiftCardData = createAsyncThunk(
    `${namespace}/purchasedGiftCardData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order=""  }, { rejectWithValue, dispatch }) => {
        try {
            console.log("TransactionList");
           let  postData = {payload : {query, page, limit, sortby, order}}
            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await purchasedGiftCardList( postData);
            console.log("TransactionList", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

// export const AddGiftCard = createAsyncThunk(
//     `${namespace}/AddGiftCard`,
//     async ({ postData }, { rejectWithValue, dispatch }) => {
//         try {
//             console.log(postData, "interdata");
//             let payload = EncryptDecrypt.encryptdata(postData, secretKey)
//             const data = await giftcardAdd({ payload: payload });
//             console.log("getCount--> ", data);
//             return data;
//         } catch (error) {
//             console.log("getCount error--->", error);
//             return rejectWithValue(error.response);
//         }
//     }
// );
// export const UpdateGiftCard = createAsyncThunk(
//     `${namespace}/UpdateGiftCard`,
//     async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
//         try {
//             console.log(updateID, "interdata");
//             let payload = EncryptDecrypt.encryptdata(postData, secretKey)
//             const data = await giftcardUpdate({ payload: payload }, updateID);
//             console.log("getCount--> ", data);
//             return data;
//         } catch (error) {
//             console.log("getCount error--->", error);
//             return rejectWithValue(error.response);
//         }
//     }
// );
export const AddWallet = createAsyncThunk(
    `${namespace}/AddWallet`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata1");
            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await giftwalletAdd({ payload: postData });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateWallet = createAsyncThunk(
    `${namespace}/UpdateWallet`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
             console.log(postData,'postDatavv');
            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await giftwalletUpdate({ payload: postData });
           
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteGiftCard = createAsyncThunk(
    `${namespace}/DeleteGiftCard`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await giftcardDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const giftcardSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearGiftLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearGiftAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearGiftUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearGiftDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [giftcardData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [giftcardData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload?.data?.datas,'payload?.data?.datas');
            // state.giftcardCount = payload?.data?.count;
            // state.giftcardDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas,"payload===")
            state.giftcardCount = payloaddatas?.count;
            state.giftcardDatas = payloaddatas?.data;
            state.receiverData = payloaddatas?.finalData;
        },
        [giftcardData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [purchasedGiftCardData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [purchasedGiftCardData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload?.data?.datas,'payload?.data?.datas');
            // state.giftcardCount = payload?.data?.count;
            // state.giftcardDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas,"payload===")
            state.purchasedGiftcardCount = payloaddatas?.count;
            state.purchasedGiftcardDatas = payloaddatas?.data;
            state.receiverData = payloaddatas?.finalData;
        },
        [purchasedGiftCardData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        // [AddGiftCard.pending](state) {
        //     state.addloading = API_STATUS.PENDING;
        // },
        // [AddGiftCard.fulfilled](state, { payload }) {
        //     state.addloading = API_STATUS.FULFILLED;
        // },
        // [AddGiftCard.rejected](state, action) {
        //     state.addloading = API_STATUS.REJECTED;
        //     state.errorMessage = action?.payload?.data;
        // },
        // [UpdateGiftCard.pending](state) {
        //     state.updateloading = API_STATUS.PENDING;
        // },
        // [UpdateGiftCard.fulfilled](state, { payload }) {
        //     state.updateloading = API_STATUS.FULFILLED;
        // },
        // [UpdateGiftCard.rejected](state, action) {
        //     state.updateloading = API_STATUS.REJECTED;
        //     state.errorMessage = action?.payload?.data;
        // },
        [AddWallet.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddWallet.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddWallet.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateWallet.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateWallet.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateWallet.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteGiftCard.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteGiftCard.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteGiftCard.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearGiftAddLoading, clearGiftUpdateLoading, clearGiftDeleteLoading, clearErrormsg } = giftcardSlice.actions;

export const giftcardSelector = (state) => state.giftcard;

export default giftcardSlice.reducer;
