import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { categoryList, categoryAdd, categoryUpdate, categoryDelete } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "category";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    categoryDatas: null,
    categoryCount: 0
};

export const categoryData = createAsyncThunk(
    `${namespace}/categoryData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", status="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await categoryList(query, page, limit, sortby, order, status);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddCategory = createAsyncThunk(
    `${namespace}/AddCategory`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await categoryAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const UpdateCategory = createAsyncThunk(
    `${namespace}/UpdateCategory`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await categoryUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteCategory = createAsyncThunk(
    `${namespace}/DeleteCategory`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await categoryDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const categorySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearCatgLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading ="initial";
            state.deleteloading ="initial";
        },
        clearCatgAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearCatgUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearCatgDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [categoryData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [categoryData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.categoryCount = payload?.data?.count;
            // state.categoryDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count,"payload")
            state.categoryCount = payloaddatas?.count;
            state.categoryDatas = payloaddatas?.data;
        },
        [categoryData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddCategory.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddCategory.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
        },
        [AddCategory.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateCategory.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateCategory.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateCategory.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteCategory.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteCategory.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteCategory.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, clearCatgAddLoading, clearCatgUpdateLoading, clearCatgDeleteLoading, clearErrormsg } = categorySlice.actions;

export const categorySelector = (state) => state.category;

export default categorySlice.reducer;
