import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import PageHeader from "../view/pageheader";
import { useDispatch, useSelector } from "react-redux";
import { reportsData, getTables } from "../store/reducer/reports";
import { reportSelector } from "../store/reducer/reports";
import { read, utils, writeFile, writeFileXLSX, CellObject } from 'xlsx';
import { API_STATUS } from "../utils/constants";
// import Select from "react-dropdown-select";
import { Button, Space } from 'antd';
import { toast } from 'react-toastify';
import XLSX from 'sheetjs-style';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';

function Reports() {
    const dispatch = useDispatch();
    const action = useRef(null);
    const [queryValue, setqueryValue] = useState('');
    const [payloadQuery, setpayloadQuery] = useState('');
    const { reportLoading, tableLoading, errorMessage, reportsResult, tableDetails, reportsMessage } = useSelector(reportSelector);
    const [tableDatas, settableDatas] = useState([])
    const [tableNames, settableNames] = useState([])
    const [columnNames, setColumnNames] = useState([])
    const [columnHeader, setcolumnHeader] = useState([])
    const [oldColumnName, setoldColumnName] = useState('')
    const [newColumnName, setnewColumnName] = useState('')
    const [submitTrue, setsubmitTrue] = useState(false)
    const [newTableNameArr, setnewTableNameArr] = useState([])
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        dispatch(getTables({}))
    }, [])

    useEffect(() => {
        if (reportLoading === API_STATUS.FULFILLED) {
            if (reportsMessage) {
                toast.error(reportsMessage)
            } else {
                toast.success('Query Submitted Sucessfully!')
                setsubmitTrue(true)
            }
        }
        if (reportLoading === API_STATUS.REJECTED) {
            toast.error(errorMessage)
        }
    }, [reportLoading, reportsMessage, errorMessage])

    useEffect(() => {
        if (tableDetails && tableDetails.length) {
            settableDatas([...tableDetails])
            let TableName = tableDetails.map((item) => {
                return Object.keys(item)[0].replace(/_/g, ' ').concat(" ", 'TABLE').toUpperCase()
            })
            if (TableName.length) {
                settableNames([...TableName])
            }
        }
    }, [tableDetails]);

    const OnselectQuery = (queryType) => {
        if (queryValue.length) {
            setqueryValue(queryValue.concat(" ", queryType))
            setpayloadQuery(payloadQuery.concat(" ", queryType))
        }
        else {
            setqueryValue(queryType)
            setpayloadQuery(queryType)
        }
    }

    const onChooseTable = (name) => {
        let existingColumn = columnNames;
        let finalArr;
        let originalTableName = name.replace(" TABLE", '').replace(/ /g, "_").toLowerCase()

        let chainReplaceText = newTableNameArr;
        chainReplaceText.push({ [name]: "v01_" + originalTableName })

        setnewTableNameArr(chainReplaceText)

        setqueryValue(queryValue.concat(" ", name))
        setpayloadQuery(payloadQuery.concat(" v01_", originalTableName));

        let setTableName = 'v01_' + originalTableName
        let columns = tableDatas.map((item) => {
            console.log(Object.keys(item)[0] == originalTableName, Object.keys(item)[0], originalTableName, 'function--Object.keys(item)[0]');
            if (Object.keys(item)[0] == originalTableName) {
                return Object.values(item)[0]
            }
        }).filter((item1) => item1 !== undefined)

        let columnValues = [];
        columns[0].map((items) => {
            columnValues.push({ tableName: name, originalTableName: setTableName, value: items, label: items.replace(/_/g, ' ').toUpperCase() })
        })

        if (columnValues.length) {
            let data = []
            data.push({ tablename: name, columnName: columnValues })
            if (existingColumn.length) {
                finalArr = existingColumn.concat(data)
                setColumnNames([...finalArr])
            } else {
                setColumnNames([...data])
            }
        }
    }

    const onChooseColumn = (value) => {
        let columnValue = value.target.value

        let combineColumnName = columnValue.tableName + '.' + columnValue.label
        let combineOldName = columnValue.originalTableName + '.' + columnValue.value

        let replaceColumnNames = '.' + columnValue.label
        let replaceOldNames = '.' + columnValue.value

        let chainReplaceText = newTableNameArr;
        chainReplaceText.push({ [replaceColumnNames]: replaceOldNames })

        setnewTableNameArr(chainReplaceText)

        setoldColumnName('.' + columnValue.value)
        setnewColumnName('.' + columnValue.label)

        setqueryValue(queryValue.concat(" ", combineColumnName))
        setpayloadQuery(payloadQuery.concat(" ", combineOldName))
    }

    const submitQuery = () => {

        let replaceData = Object.assign({}, ...newTableNameArr)

        function allReplace(str, obj) {
            for (const x in obj) {
                console.log(x, obj[x], 'newTableNameArr--00');
                str = str.replace(new RegExp(x, 'g'), obj[x]);
            }
            return str;
        };

        let replacedTablevalue = allReplace(queryValue, replaceData);
        // let columnn = replacedTablevalue.replace(newColumnName, oldColumnName)

        let postData = replacedTablevalue
        dispatch(reportsData({ postData }));
    }

    const clearQuery = () => {
        setpayloadQuery('')
        setqueryValue('')
        setColumnNames([])
        setoldColumnName('')
        setnewColumnName('')
        setnewTableNameArr([])
    }

    const clearColumns = () => {
        setColumnNames([])
    }

    const renderSelectGroup = (table) => {
        const items = table.columnName.map((item, i) => {
            return (
                <MenuItem key={i} value={item}>
                    {item.label}
                </MenuItem>
            );
        });

        return [<ListSubheader style={{ fontWeight: 'bold', color: 'black' }}>{table.tablename}</ListSubheader>, items, <hr></hr>];
    };

    const ColumnWidthSetup = (data) => {
        const columnWidths = [];
        for (const property in data[0]) {
            columnWidths.push({ wch: Math.max(property ? property.toString().length : 0, ...data.map(obj => obj[property] ? obj[property].toString().length : 0)) });
        }
        return columnWidths;
    };

    const exportFile = useCallback(() => {
        if (reportsResult && reportsResult.length) {
            let Heading = reportsResult.map((item) => {
                return Object.keys(item).map((columnName) => {
                    return columnName.replace(/_/g, ' ').concat(" ", 'COLUMN').toUpperCase()
                })
            });

            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, Heading);
            ws['!cols'] = ColumnWidthSetup(Heading);
            // ws
            // ws['!rows'] = [
            //     { 'hpt': 20 }
            // ]
            // ws['A1'] = { font: { sz: 11 } }
            utils.sheet_add_json(ws, reportsResult, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'SQL_Query_Report');
            writeFile(wb, 'Reports.xlsx');
        }
        else {
            toast.error("No Records To show!");
        }
        setsubmitTrue(false)

    }, [reportsResult]);

    return (
        <div>
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Reports"
                        Breadcrumb={[{ name: "Reports" }]}
                    />
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="header">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>Reports</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className={"body"}>
                                    <form className="ng-untouched ng-dirty ng-invalid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h6>SQL Commands</h6>
                                                < div style={{ paddingTop: '8px' }}>
                                                    <button className="btn btn-outline-primary" onClick={(e) => { e.preventDefault(); OnselectQuery('SELECT') }} >SELECT</button>
                                                    <button className="btn btn-outline-primary mx-2" onClick={(e) => { e.preventDefault(); OnselectQuery('LEFT JOIN') }} >LEFT JOIN</button>
                                                    <button className="btn btn-outline-primary" onClick={(e) => { e.preventDefault(); OnselectQuery('INNER JOIN') }} >INNER JOIN</button>
                                                    <button className="btn btn-outline-primary ms-2" onClick={(e) => { e.preventDefault(); OnselectQuery('WHERE') }} >WHERE</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-12">
                                                <h6>Table Name</h6>
                                                < div style={{ paddingTop: '8px' }}>
                                                    {/* {tableNames && tableNames.map((item) => {
                                                        console.log(item, 'item');
                                                        return (
                                                            < button className="btn btn-outline-secondary ms-2" >{item}</button>
                                                        )
                                                    }
                                                    )} */}
                                                    <Space size={[8, 16]} wrap>
                                                        {tableNames && tableNames.length ? tableNames.map((item, i) => (
                                                            <Button className={`checkbox-button ${checked ? "checkbox-button-checked" : ""}`} key={i} onClick={(e) => { e.preventDefault(); onChooseTable(item) }}  >{item}</Button>
                                                        )) : ''}
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-6">
                                                <h6>Choose Column Name</h6>
                                                <FormControl sx={{ m: 2, minWidth: 400 }}>
                                                    <InputLabel htmlFor="grouped-select">Columns</InputLabel>
                                                    <Select id="grouped-select" action={action} endAdornment label="Columns" onChange={(values) => { onChooseColumn(values) }}
                                                    >
                                                        {columnNames && columnNames.length ? columnNames?.map(table => renderSelectGroup(table)) : <ListSubheader><i class="fa fa-info-circle mr-2"></i>Choose Table to Display Columns</ListSubheader>}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="col-md-6 ">
                                                <button class="col-md-4 mt-5 btn btn-danger" onClick={(e) => { e.preventDefault(); clearColumns() }} >CLEAR COLUMNS</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-12 ">
                                                <h6>Enter SQL Query</h6>
                                                < div style={{ paddingTop: '8px' }}>
                                                    <textarea className="w-100" style={{ height: 100 }} name="query" placeholder="Enter Your SQL Query" value={queryValue} onChange={(e) => {
                                                        console.log(e.target.value, 'dataaaaaa');
                                                        // setpayloadQuery(e.target.value)
                                                        setqueryValue(e.target.value)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>

                                        <button class="offset-md-2 col-md-2 mt-3 btn btn-danger" onClick={(e) => { e.preventDefault(); clearQuery() }} >CLEAR</button>
                                        <button id="submit_btn" class="offset-md-1 col-md-2 mt-3 btn btn-success" onClick={(e) => { e.preventDefault(); submitQuery() }} >SUBMIT</button>
                                        {submitTrue ?
                                            <button id="submit_btn" class="offset-md-1 col-md-2 mt-3 btn btn-primary" onClick={exportFile} >EXPORT REPORT</button>
                                            : ''}
                                    </form>
                                </div>
                            </div>
                            {/* {submitTrue ?
                                <div className="card p-2">
                                    <button id="submit_btn" class="offset-md-5 col-md-2 mt-3 btn btn-primary" onClick={exportFile} >EXPORT REPORT</button>
                                </div>
                                : ''} */}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Reports
