import React, { useState } from "react";
import PageHeader from "../../view/pageheader";
import { Button, Card, Table } from "antd";

function Credentials() {

    const [copyState, setCopyState] = useState(false)
    const [password,setPassword] = useState(false)

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    const columns = [
        {
            title: 'Module',
            dataIndex: 'module_name',
            key: 'module_name',
            //   render: (text) => <a>Hiii</a>,
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => <a href={`${record.url}`} target="_blank">{record.url}</a>
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            render: (text, record, index) => {
                if (record.username != '') {
                    return (
                        <span>{record.username}<br/><Button className="mt-2" type="primary" style={{backgroundColor:copyState === index ? 'green' : ''}} onClick={() => {
                            setCopyState(index)
                            setPassword(true)
                            copy(record.username)
                        }}>{copyState === index ? 'Copied' : 'Copy'}</Button></span>
                    )
                }
                else {
                    return <span><center>-</center></span>
                }
            }
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (text, record,index) => {
                if (record.password != '') {
                    return (
                        <span>{record.securedPassword}<br/><Button className="mt-2" type="primary" style={{backgroundColor:password === index ? 'green' : ''}} onClick={() => {
                            // if(record.key === )
                            setPassword(index)
                            setCopyState(true)
                            copy(record.password)

                        }}>{password === index ? 'Copied' : 'Copy'}</Button></span>
                    )
                } else {
                    return <span><center>-</center></span>
                }
            }
        }
    ]

    const data = [
        {
            key: 0,
            module_name: 'Admin',
            url: `${process.env.REACT_APP_ADMIN_URL}`,
            username: 'horizonadmin@yopmail.com',
            password: 'horizonadmin@1234',
            securedPassword:'***************'
        },
        {
            key: 1,
            module_name: 'Website',
            url: `${process.env.REACT_APP_WEBSITE_URL}`,
            username: '',
            password: ''
        },
        {
            key: 2,
            module_name: 'Kitchen Display',
            url: `${process.env.REACT_APP_KITCHEN_URL}`,
            username: 'kitchendisplay@yopmail.com',
            password: 'kitchendisplay@1234',
            securedPassword:'***************'

        },
        {
            key: 3,
            module_name: 'Order Dispatch',
            url: `${process.env.REACT_APP_ORDER_DISPATCH_URL}`,
            username: 'kitchendispatch@yopmail.com',
            password: 'kitchendispatch@1234',
            securedPassword:'***************'

        },

    ];

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Credentials"
                    Breadcrumb={[{ name: "Credentials" }]}
                />
                <Card>
                    <Table columns={columns} dataSource={data} pagination={false} />
                </Card>
            </div>

        </div>
    )

}

export default Credentials