
export const generalFields = [
    { label: "Sales Tax % *", type: "text", name: "tax", placeHolder: "Tax Setting", class: "", value: "", tvalue: "", input: "text", validation: "required|numeric|min:0,num|max:100,num" },
    { label: "Sales Discount % *", type: "text", name: "discount", placeHolder: "Discount Setting", class: "", value: "", tvalue: "", input: "text", validation: "required|numeric|min:0,num|max:100,num" },
    // { label: "Available Printers", type: "text", name: "printers", placeHolder: "Printer", class: "", value: [], input: "dropdown", validation: "", options:"printers" },
    // { label: "Printer Chosen", type: "text", name: "printer", placeHolder: "Message", class: "", value: "", input: "text", validation: "" },
    { label: "Front-Window Printer", type: "text", name: "front_printer", placeHolder: "Printers", class: "", value: [], input: "dropdowns", validation: "", options: "printers" },
    { label: "Kitchen Printer", type: "text", name: "kitchen_printer", placeHolder: "Printers", class: "", value: [], input: "dropdowns", validation: "", options: "printers" },
    { label: "Latitude", type: "text", name: "latitude", placeHolder: "Latitude", class: "", value: "", input: "text", validation: "numeric" },
    { label: "Longitude", type: "text", name: "longitude", placeHolder: "Longitude", class: "", value: "", input: "text", validation: "numeric" },
    { label: "Map Radius", type: "text", name: "radius", placeHolder: "Map Radius", class: "", value: "", input: "text", validation: "numeric|min:0,num|max:1000,num" },
    { label: "Time Zone", type: "text", name: "timezone", placeHolder: "Message", class: "", value: [], input: "dropdown", validation: "", options: "timezone" },
    { label: "Disclaimer Message *", type: "text", name: "disclaimer", placeHolder: "Message", class: "", value: "", input: "textarea", validation: "required" }];
