import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generalList, generalUpdate, printerList } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "general";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    printerloading: "initial",
    errorMessage: null,
    generalDatas: null,
    printerlist: null,
    generalCount: 0
};

export const generalData = createAsyncThunk(
    `${namespace}/generalData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(generaltData, secretKey)
            const data = await generalList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const Updategeneral = createAsyncThunk(
    `${namespace}/Updategeneral`,
    async ({ generaltData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(generaltData, secretKey)
            const data = await generalUpdate({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getConnectedPrinters = createAsyncThunk(
    `${namespace}/getConnectedPrinters`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            const data = await printerList();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const generalSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        cleargeneralLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        cleargeneralAddLoading: (state) => {
            state.addloading = "initial";
        },
        cleargeneralUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        cleargeneralDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [generalData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [generalData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.generalCount = payload?.data?.count;
            // state.generalDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.response, "payload")
            state.generalCount = payloaddatas?.count;
            state.generalDatas = payloaddatas?.response;
        },
        [generalData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [Updategeneral.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [Updategeneral.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [Updategeneral.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [getConnectedPrinters.pending](state) {
            state.printerloading = API_STATUS.PENDING;
        },
        [getConnectedPrinters.fulfilled](state, { payload }) {
            state.printerloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            state.printerlist = payloaddatas.connectedPrinterlist
        },
        [getConnectedPrinters.rejected](state, action) {
            state.printerloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, cleargeneralAddLoading, cleargeneralUpdateLoading, cleargeneralDeleteLoading, clearErrormsg } = generalSlice.actions;

export const generalSelector = (state) => state.general;

export default generalSlice.reducer;
