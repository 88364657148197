import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/assets/css/orderprint.css";
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import { orderById, orderSelector, clearData, } from "../store/reducer/order";
import { API_STATUS } from "../utils/constants";
import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import dayjs from 'dayjs';
import { assestURL, DateTimeFormat } from "../services/config";
import moment from 'moment';


function OrderView() {

    const params = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const { loading, errorMessage, orderDataById } = useSelector(orderSelector);
    console.log("orderdetails", orderDataById);
    const [orderID, setorderID] = useState(params?.id);

    let custom_order = [];

    const print = () => {
        window.print();
    }

    const ItemPrice = (reward, product_price, quantity, order_details) => {
        console.log(reward, product_price, quantity, order_details)
        let custom_cost = parseFloat(product_price);
        if (order_details && order_details.length > 0) {
            order_details.map((item) => {
                console.log(item?.item_name, item?.item_cost, "item_cost")
                custom_cost += parseFloat(item?.item_cost);
            })
        }
        if (reward == 1) {
            return (quantity * (custom_cost)) + " Points";
        } else {
            return "$" + parseFloat(quantity * (custom_cost)).toFixed(2);
        }
    }

    useEffect(() => {
        dispatch(clearData())
        dispatch(orderById({ orderID }));

    }, [])


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Orders"
                    Breadcrumb={[{ name: "Orders" }]}
                />
                <div className="row clearfix" id="print-order" >
                    <div className="col-md-12">

                        <div className="card p-2">
                            <div className="body">
                                <div id="orderlogo">
                                    <div className="row" >
                                        <img src="https://dev.infognana.com/dipmenulive/froentend/images/login-logo.png" alt="orderlogo" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>
                                            Order Details : #{orderDataById?.id}
                                            <strong className="text-primary"></strong>
                                        </h3>
                                    </div>
                                    <div className="col-md-4 row">
                                        <div className="col-md-12" id="order-action">
                                            <Link to='/orders' className="btn btn-primary mr-1  float-right"> Back</Link>
                                            <button className="btn btn-outline-secondary mr-3 float-right" onClick={print}>
                                                <i className="icon-printer"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="tab-content">
                                    <div className="active">
                                        <div className="row clearfix">
                                            <div className="col-md-6 col-sm-6">
                                                <address>
                                                    <strong>{orderDataById?.customer?.first_name + " " + orderDataById?.customer?.last_name}</strong>
                                                    <br /> {orderDataById?.customer?.location}
                                                    <br />Phone: {orderDataById?.customer?.mobile}
                                                    <br />E-mail: {orderDataById?.customer?.email}
                                                </address>
                                            </div>
                                            <div className="col-md-6 col-sm-6 text-right">
                                                <p>
                                                    <strong>Order ID: </strong> {orderDataById?.id}
                                                </p>
                                                <p className="m-b-0">
                                                    {/* <strong>Order Date: </strong> {dayjs(orderDataById?.created_at).format(DateTimeFormat)} */}
                                                    <strong>Order Date: </strong>{moment(orderDataById?.created_at).tz('America/Chicago').format('MM-DD-YYYY hh:mm A')}
                                                </p>
                                                {orderDataById?.schedulepickup_time ?
                                                    <p className="m-b-0">
                                                        <strong>Schedule Pickup: </strong> {orderDataById?.schedulepickup_time ? orderDataById?.schedulepickup_time : '-'}
                                                    </p>
                                                    : ''}
                                                <p className="m-b-0">
                                                    <strong>Order Mode: </strong> {orderDataById?.order_mode === '1' ? 'Drive-Thru' : orderDataById?.order_mode === '2' ? 'Front-Window' : ''}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <table className="table table-hover">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>#</th>
                                                                <th width="40px">Description</th>
                                                                <th>Quantity</th>
                                                                <th >Amount</th>
                                                                <th className="text-right">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                orderDataById?.order_details && orderDataById?.order_details.map((item, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.product_name + " (" + item?.itemsize_name + ")"} <br />
                                                                                Custom Items :
                                                                                <div style={{ width: "500px", "white-space": "break-spaces" }}>
                                                                                    {
                                                                                        _.filter(orderDataById?.order_item_details, { order_detail_id: item.id }).map((customitem, index_sub, row) => {
                                                                                            return (<>{customitem.item_name} {index_sub === row.length - 1 ? "" : ", "}</>
                                                                                            )
                                                                                        })
                                                                                    }</div>

                                                                            </td>
                                                                            <td>{item?.quantity}</td>
                                                                            {/* <td >{(item?.reward == 1) ? "" : "$"}{(item?.reward == 1) ? item?.product_price : parseFloat(item?.product_price).toFixed(2)}{(item?.reward == 1) ? " Points" : ""}</td> */}
                                                                            <td>{ItemPrice(item?.reward, item?.product_price, item?.quantity, _.filter(orderDataById?.order_item_details, { order_detail_id: item.id }))}</td>
                                                                            <td className="text-right">{ItemPrice(item?.reward, item?.product_price, item?.quantity, _.filter(orderDataById?.order_item_details, { order_detail_id: item.id }))}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row clearfix">
                                            <div className="col-md-6">

                                            </div>
                                            <div className="col-md-6 text-right">
                                                <div className="row">
                                                    <div className="col-md-9"><b>Subtotal ({orderDataById?.order_details.length} items) :</b></div>
                                                    <div className="col-md-3">{"$" + orderDataById?.sub_total}</div>
                                                </div>
                                                
                                                {orderDataById?.redeem_points != null && orderDataById?.redeem_points != 0 ? <div className="row">
                                                    <div className="col-md-9"><p className="m-b-0">Reward Points :</p></div>
                                                    <div className="col-md-3">{orderDataById?.redeem_points} pts</div>
                                                </div> : ""}
                                                <div className="row">
                                                    <div className="col-md-9"><p className="m-b-0">Tax ({orderDataById?.tax_percent}%) :</p></div>
                                                    <div className="col-md-3">{"$" + orderDataById?.tax_amount}</div>
                                                </div>
                                                {orderDataById?.gift_point != null && orderDataById?.gift_point != 0 ? <div className="row">
                                                    <div className="col-md-9"><p className="m-b-0">Gift Card :</p></div>
                                                    <div className="col-md-3">$ {orderDataById?.gift_point}</div>
                                                </div> : ""}
                                                <hr />
                                                <div className="row ">
                                                    <div className="col-md-9"><p className="m-b-0">Total :</p></div>
                                                    <div className="col-md-3">{(orderDataById?.type != 'reward') ? "$" + orderDataById?.order_total : orderDataById?.redeem_points + " Points"} </div>
                                                </div>
                                            </div>
                                            <div className="hidden-print col-md-12 text-right">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderView;
