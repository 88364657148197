import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from "./components/login";
import NavBar from "./view/navbar";
import Kitchendispatch from './components/kitchendispatch';
import KitchenDisplay from './components/kitchenDisplay';

console.log("inasdsad");
const PrivateRoute = () => {
    console.log("inasdsad");
    const isAuth = sessionStorage.getItem('isAuthenticated');
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    const roleID = localStorage.getItem('role_id')
    console.log(roleID, 'roleID==');
    if (!isAuthenticated) {
        localStorage.clear();
    }
    return ((isAuthenticated && roleID == '4')
        ?
        // < Navigate to="/kitchendisplay" />
        <div id="wrapper">
            <div>
                <NavBar />
            </div>
            <div style={{ marginTop: '75px' }}>
                {/* <div id="main-content"> */}
                <KitchenDisplay />
            </div>
            {/* </div> */}
        </div>
        :
        (isAuthenticated && roleID == '5') ?
            <div id="wrapper">
                <div>
                    <NavBar />
                </div>
                <div style={{ marginTop: '75px' }}>
                    <Kitchendispatch />
                </div>
            </div>
            //     <Navigate to="/kitchendispatch" />
            :
            isAuthenticated ? <>
                <div id="wrapper">
                    <NavBar />
                    <div id="main-content">
                        <Outlet />
                    </div>
                </div>
            </>
                : <Navigate to="/login" />);
};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)


const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(PrivateRoute);