import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { TimePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
    generalData,
    Updategeneral,
    generalSelector,
    clearLoadingDatas,
    cleargeneralAddLoading,
    cleargeneralUpdateLoading,
    cleargeneralDeleteLoading,
    clearData,
    clearErrormsg,
    getConnectedPrinters
} from "../../store/reducer/general";
import { API_STATUS } from "../../utils/constants";
import {
    generalFields
} from "../fields/generalform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../../services/config";

function General() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { generalDatas, loading, addloading, deleteloading, updateloading, errorMessage, printerlist } = useSelector(generalSelector);
    const resetForm = generalFields;
    const [formFields, setFormFields] = useState(generalFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const timezoneDropdown = [{ name: 'CST - America/Chicago', id: "America/Chicago" }, { name: 'PST - America/Los_Angeles', id: "America/Los_Angeles" }, { name: 'America/Detroit', id: "America/Detroit" }, { name: 'MST - America/Boise', id: "America/Boise" }]
    const [connectedPrinters, setconnectedPrinters] = useState([]);
    const [kitchenPrinterArray, setKitchenPrinterArray] = useState([]);
    const [frontWindowPrinterArray, setFrontWindowPrinterArray] = useState([])
    const [printerValue, setPrinterValue] = useState({
        frontWindowVal: '',
        kitchenVal: ''
    })
    console.log(connectedPrinters, 'connectedPrinters');

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");

    const format = 'hh:mm A';
    //const formDatas = _.map()

    console.log(printerlist, connectedPrinters, 'printerDatas==');

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        generalDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        generalDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value, 'valll')
        formFields[index].value = value;
        setFormFields([...formFields]);
        let temp = []
        if (((connectedPrinters.length > 0) && (value && value.length > 0))) {
            for (let i in connectedPrinters) {
                if ((connectedPrinters[i].name) == value[0].name) {
                    console.log('condition-truee');
                    temp.push({
                        isDefault: connectedPrinters[i].isDefault,
                        name: connectedPrinters[i].name,
                        options: {
                            device_uri: connectedPrinters[i].options.device_uri,
                            printer_info: connectedPrinters[i].options.printer_info,
                            printer_location: "",
                            printer_make_and_model: connectedPrinters[i].options.printer_make_and_model,
                            printer_type: connectedPrinters[i].options.printer_type
                        },
                        disabled: true
                    })
                }
                else {
                    temp.push({
                        isDefault: connectedPrinters[i].isDefault,
                        name: connectedPrinters[i].name,
                        options: {
                            device_uri: connectedPrinters[i].options.device_uri,
                            printer_info: connectedPrinters[i].options.printer_info,
                            printer_location: "",
                            printer_make_and_model: connectedPrinters[i].options.printer_make_and_model,
                            printer_type: connectedPrinters[i].options.printer_type
                        },
                        disabled: false
                    })
                }
            }
            console.log(temp, 'temp');
            setconnectedPrinters(temp)
        }
    };

    const frontWindowPrinterOnChange = (value, index) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
        let temp = []
        if (((kitchenPrinterArray.length > 0) && (value && value.length > 0))) {
            for (let i in kitchenPrinterArray) {
                if ((kitchenPrinterArray[i].name) == value[0].name) {
                    console.log('condition-truee');
                    temp.push({
                        isDefault: kitchenPrinterArray[i].isDefault,
                        name: kitchenPrinterArray[i].name,
                        options: {
                            device_uri: kitchenPrinterArray[i].options.device_uri,
                            printer_info: kitchenPrinterArray[i].options.printer_info,
                            printer_location: "",
                            printer_make_and_model: kitchenPrinterArray[i].options.printer_make_and_model,
                            printer_type: kitchenPrinterArray[i].options.printer_type
                        },
                        disabled: true
                    })
                }
                else {
                    temp.push({
                        isDefault: kitchenPrinterArray[i].isDefault,
                        name: kitchenPrinterArray[i].name,
                        options: {
                            device_uri: kitchenPrinterArray[i].options.device_uri,
                            printer_info: kitchenPrinterArray[i].options.printer_info,
                            printer_location: "",
                            printer_make_and_model: kitchenPrinterArray[i].options.printer_make_and_model,
                            printer_type: kitchenPrinterArray[i].options.printer_type
                        },
                        disabled: false
                    })
                }
            }
            console.log(temp, 'temp');
            setKitchenPrinterArray(temp)
        }
    }

    const kitchenPrinteronChange = (value, index) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
        let temp = []
        if (((frontWindowPrinterArray.length > 0) && (value && value.length > 0))) {
            for (let i in frontWindowPrinterArray) {
                if ((frontWindowPrinterArray[i].name) == value[0].name) {
                    console.log('condition-truee');
                    temp.push({
                        isDefault: frontWindowPrinterArray[i].isDefault,
                        name: frontWindowPrinterArray[i].name,
                        options: {
                            device_uri: frontWindowPrinterArray[i].options.device_uri,
                            printer_info: frontWindowPrinterArray[i].options.printer_info,
                            printer_location: "",
                            printer_make_and_model: frontWindowPrinterArray[i].options.printer_make_and_model,
                            printer_type: frontWindowPrinterArray[i].options.printer_type
                        },
                        disabled: true
                    })
                }
                else {
                    temp.push({
                        isDefault: frontWindowPrinterArray[i].isDefault,
                        name: frontWindowPrinterArray[i].name,
                        options: {
                            device_uri: frontWindowPrinterArray[i].options.device_uri,
                            printer_info: frontWindowPrinterArray[i].options.printer_info,
                            printer_location: "",
                            printer_make_and_model: frontWindowPrinterArray[i].options.printer_make_and_model,
                            printer_type: frontWindowPrinterArray[i].options.printer_type
                        },
                        disabled: false
                    })
                }
            }
            console.log(temp, 'temp');
            setFrontWindowPrinterArray(temp)
        }
    }

    const CheckOrRadioChange = (value, index) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const onChangeTime = (value, time, index, name) => {
        console.log(value, time, "test");
        if (name == "tax") {
            let cval = new Date(value);
            let getdata = _.filter(formFields, { name: "start_time" })
            console.log(value, getdata[0].tvalue);
            if (getdata[0].tvalue > value) {
                return false;
            }
        }
        if (name == "start_time") {
            let getdata = _.filter(formFields, { name: "end_time" })
            console.log(value, getdata[0].tvalue);
            if (getdata[0].tvalue < value) {
                return false;
            }
        }
        formFields[index].value = time;
        formFields[index].tvalue = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));
    const submitGeneral = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            //const generaltData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "timezone") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val && val[0].id;
                } else if (data?.name === "kitchen_printer") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val && val ? val[0].name : '';
                } else if (data?.name === "front_printer") {
                    let val = data.value;
                    console.log(val, 'val')
                    //data.value = val[0].id;
                    return arr[data?.name] = val && val ? val[0].name : '';
                }
                else {
                    return arr[data?.name] = data.value;
                }
                //return arr[data?.name] = data.value;
            });
            arr.id = updateID;
            const generaltData = arr;
            console.log(generaltData, "generaltData");
            //return false;
            //const generaltData = arr;
            dispatch(Updategeneral({ generaltData }));

            setshowAdd(false);
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }

            setShowvalidator(true);
            console.log("test");
        }
    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };


    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        generalDispatch();
    };

    const generalDispatch = () => {
        dispatch(
            generalData({})
        );
    };

    useEffect(() => {
        dispatch(clearData())
        generalDispatch()
        dispatch(getConnectedPrinters({}))
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(formFields, "formFields")
    }, [formFields])

    useEffect(() => {
        console.log(generalDatas, "formFields")
        if (generalDatas && typeof (generalDatas[0].id) !== "undefined" && (printerlist && printerlist)) {
            console.log(generalDatas, "editDatas in")
            const row = generalDatas[0];
            setUpdateID(row.id);
            let chosen_data = []
            let data = formFields.map((data, index) => {
                //console.log(_.filter(statusDropdown, { id: row.status }));
                if (data.name === "tax") {
                    data.value = row.tax;
                }
                if (data.name === "discount") {
                    data.value = row.discount;
                }
                if (data.name === "printer")
                    data.value = row.printer;
                if (data.name === "latitude")
                    data.value = row.latitude;
                if (data.name === "longitude")
                    data.value = row.longitude;
                if (data.name === "radius")
                    data.value = row.radius;
                if (data.name === "disclaimer")
                    data.value = row.disclaimer;
                if (data.name === "timezone") {
                    let set_status = _.filter(timezoneDropdown, { id: row.timezone });
                    data.value = set_status;
                }
                if (data.name === "front_printer") {
                    let set_status = _.filter(printerlist, { name: row.front_printer });
                    console.log(set_status[0], printerlist, 'front_printer');
                    data.value = set_status;
                }
                if (data.name === "kitchen_printer") {
                    let set_status = _.filter(printerlist, { name: row.kitchen_printer });
                    console.log(set_status[0], printerlist, 'front_printer==l');
                    data.value = set_status;
                }
                return data;
            })
            setFormFields(data);
        }
    }, [generalDatas, printerlist])

    useEffect(() => {
        if (printerlist) {
            setconnectedPrinters([...printerlist])
            setKitchenPrinterArray([...printerlist])
            setFrontWindowPrinterArray([...printerlist])
        }
    }, [printerlist])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(cleargeneralDeleteLoading())
            generalDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(cleargeneralAddLoading())
            generalDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(cleargeneralUpdateLoading())
            resetFormFields();
            generalDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);

    console.log(connectedPrinters, 'connectedPrinters');


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="General"
                    Breadcrumb={[{ name: "General" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>General</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={"body"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                console.log(fields, 'fields');
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={(fields?.options == "timezone") ? timezoneDropdown : (fields?.options == "printers") ? connectedPrinters.name : statusDropdown}
                                                                            values={fields?.value}
                                                                            disabled={formFields.includes(fields?.value) ? true : false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        /> :
                                                                        (fields?.input == "dropdowns" && fields?.options != 'printers') ?
                                                                            <Select
                                                                                className="js-states"
                                                                                placeholder=""
                                                                                // options={(fields?.options == "timezone") ? timezoneDropdown : (fields?.options == "printers") ? connectedPrinters : statusDropdown}
                                                                                options={(fields?.options == "timezone") ? timezoneDropdown : (fields?.options === "printers") ? connectedPrinters : statusDropdown}
                                                                                values={fields?.value}
                                                                                // disabled={false}
                                                                                disabled={formFields.includes(fields?.value) ? true : false}
                                                                                multi={false}
                                                                                dropdownHandle={true}
                                                                                searchable={false}
                                                                                labelField={"name"}
                                                                                valueField={"name"}
                                                                                onChange={(value) => { DropdownChange(value, index); }}
                                                                            /> :
                                                                            (fields?.label == "Front-Window Printer") ?
                                                                                <Select
                                                                                    className="js-states"
                                                                                    placeholder=""
                                                                                    // options={(fields?.options == "timezone") ? timezoneDropdown : (fields?.options == "printers") ? connectedPrinters : statusDropdown}
                                                                                    options={frontWindowPrinterArray}
                                                                                    values={fields?.value}
                                                                                    // disabled={false}
                                                                                    disabled={formFields.includes(fields?.value) ? true : false}
                                                                                    multi={false}
                                                                                    dropdownHandle={true}
                                                                                    searchable={false}
                                                                                    labelField={"name"}
                                                                                    valueField={"name"}
                                                                                    onChange={(value) => { frontWindowPrinterOnChange(value, index); }}
                                                                                /> :
                                                                                (fields?.label == "Kitchen Printer") ?
                                                                                    <Select
                                                                                        className="js-states"
                                                                                        placeholder=""
                                                                                        // options={(fields?.options == "timezone") ? timezoneDropdown : (fields?.options == "printers") ? connectedPrinters : statusDropdown}
                                                                                        options={kitchenPrinterArray}
                                                                                        values={fields?.value}
                                                                                        // disabled={false}
                                                                                        disabled={formFields.includes(fields?.value) ? true : false}
                                                                                        multi={false}
                                                                                        dropdownHandle={true}
                                                                                        searchable={false}
                                                                                        labelField={"name"}
                                                                                        valueField={"name"}
                                                                                        onChange={(value) => { kitchenPrinteronChange(value, index); }}
                                                                                    />
                                                                                    : (fields?.input == "radio") ?
                                                                                        <>&nbsp;
                                                                                            <Tooltip title={fields?.placeHolder}>
                                                                                                <span className="icon-question"></span>
                                                                                            </Tooltip><br />
                                                                                            {fields?.options && fields.options.map((item) => {
                                                                                                return <label class="fancy-radio">
                                                                                                    <input type="radio" className={fields?.class}
                                                                                                        value={item?.value}
                                                                                                        name={fields?.name}
                                                                                                        checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                                        onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                                </label>
                                                                                            })}
                                                                                        </>
                                                                                        : (fields?.input == "timepicker") ?
                                                                                            <TimePicker onChange={(e, timeString) => {
                                                                                                console.log(timeString);
                                                                                                onChangeTime(e, timeString, index, fields?.name);
                                                                                            }} value={dayjs(fields?.value, format)} format={format}
                                                                                                className="form-control" allowClear={false} />

                                                                                            : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitGeneral(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default General;
