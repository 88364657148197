
export const giftcardFields = [
    { label: "User *", type: "text", name: "user_id", placeHolder: "User Name", class: "", value: [], input: "dropdown", validation: "required" },
    { label: "First Name ", type: "text", name: "first_name", placeHolder: "First Name", class: "", value: "", input: "text", validation: "", readonly:true },
    { label: "Last Name ", type: "text", name: "last_name", placeHolder: "Last Name", class: "", value: "", input: "text", validation: "" ,readonly:true},
    { label: "Email ", type: "text", name: "email_id", placeHolder: "Email", class: "", value: "", input: "text", validation: "" ,readonly:true},
    { label: "Mobile Number", type: "text", name: "mobile", placeHolder: "Mobile Number", class: "", value: "", input: "text", validation: "" ,readonly:true},
    // { label: "Gift Card ID *", type: "text", name: "giftcard_id", placeHolder: "Gift Card ID", class: "", value: "", input: "text", validation: "required" },
    { label: "Load Amount($) *", type: "text", name: "load_points", placeHolder: "Load Amounts($)", class: "", value: "", input: "text", validation: "required|max:15" },
    { label: "Balance Wallet Amount($)", type: "text", name: "wallet_points", placeHolder: "Balance Amounts($)", class: "", value: "", input: "text", validation: "",readonly:true }];
    // { label: "Message", type: "text", name: "message", placeHolder: "Message", class: "", value: "", input: "textarea", validation: "" }];
    // { label: "Date Range *", type: "text", name: "date", placeHolder: "Date Range", class: "", value: "", input: "rangepicker", validation: "" }];