import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate,Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { DatePicker, Space } from 'antd';
import { imgaeUpload } from "../../services/api";
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import {
    bannerData,
    AddBanner,
    UpdateBanner,
    DeleteBanner,
    bannerSelector,
    clearLoadingDatas,
    clearBannAddLoading,
    clearBannUpdateLoading,
    clearBannDeleteLoading,
    clearData,
    clearErrormsg
} from "../../store/reducer/banner";
import { API_STATUS } from "../../utils/constants";
import {
    bannerFields
} from "../fields/bannerform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../../services/config";

function AddBanners() {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { bannerDatas, bannerCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(bannerSelector);
    const resetForm = bannerFields;
    const [formFields, setFormFields] = useState(bannerFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [uploadfiles, setUploadFiles] = useState([]);

    let [thumbsimage, setThumbsImage] = useState([]);
    const maxProductWidth = 1200;
    const maxProductHeight = 600;

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };



    const onChangeTime = (value, time, index, name) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        setUploadFiles(...uploadfiles, acceptedFiles);
        let formdata = new FormData();
        formdata.append("file", acceptedFiles)


        //setfileUpload(binaryStr);
        acceptedFiles.forEach((file) => {
            console.log(file);
            formdata.append("fileToUpload", file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result

                const img = new Image();
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;


                    if (width != maxProductWidth || height != maxProductHeight) {
                        const errorMessage = {
                            file,
                            errors: [
                                {
                                    code: "file-invalid-dimensions",
                                    message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
                                },
                            ],
                        };

                        rejectedFiles.push(errorMessage);
                        setThumbsImage([]);

                    } else {

                        console.log(`Image dimensions: ${width}x${height}`);
                        setfileUpload(binaryStr);
                        setfilePath(file.path);
                        setfileerror(false);
                    }
                };
                img.src = binaryStr;
            }
            reader.readAsDataURL(file)
        })

        //const data = await imgaeUpload(formdata);
        //console.log("getCount--> ", data);
    }, [])

    useEffect(() => {
        const thumbs = files.map(file => (
            <div className="dropzone_thum_img" key={file.name}>
                <div className="dropzone_thumb">
                    <img
                        src={file.preview}
                        className="dropzone_img"
                        // Revoke data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                </div>
            </div>
        ));
        setThumbsImage(thumbs);
    }, [files])



    const { getRootProps, getInputProps, fileRejections } = useDropzone({

        onDrop, accept: {
            'image/*': [],
        }, multiple: true,
        maxSize: 1024000,

    })



    const fileRejectionItems = fileRejections.map(({ file, errors }) => (

        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>
            ))}
        </div>
    ));
    const submitBanner = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            if (filesizeerror == true) {
                return false
            }

            const datas = formFields.map((data) => {
                if (data?.name === "status") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val[0].id;
                } else {
                    return arr[data?.name] = data.value;
                }
            });
            console.log(arr);
            if (arr.date != "") {
                arr.from_date = dayjs(new Date(arr.date[0])).format('YYYY-MM-DD')
                arr.to_date = dayjs(new Date(arr.date[1])).format('YYYY-MM-DD')
            } else {
                arr.from_date = null
                arr.to_date = null
            }
            const postDatas = arr;
            console.log(postDatas, "postDatas")
            //return false;
            dispatch(AddBanner({ postDatas, uploadfiles }));
        } else {
            console.log(fileUpload, updateID)
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            setShowvalidator(true);
            console.log("test123");
        }
    };

    useEffect(() => {
    }, [])

    useEffect(() => {
        simpleValidator.current.showMessages()
        console.log(loading, "loading")
        if (addloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearBannAddLoading())
            navigate('/banner');
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
    }, [addloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Banner"
                    Breadcrumb={[{ name: "Add Banner" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Add Banner</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-12">
                                            <Link to='/banner' className="btn btn-primary mr-1  float-right"> Cancel</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"body"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className={(fields?.input == "file") ? "col-md-12" : "col-md-6"}>
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.name}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={statusDropdown}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        : (fields?.input == "file") ?
                                                                            <>&nbsp;
                                                                                <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                <section className="row container">
                                                                                    <div className="col-md-8">
                                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                                            <input {...getInputProps()}
                                                                                                name={fields?.name} />
                                                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                        </div></div>
                                                                                    <div className="col-md-12">
                                                                                        <div className="thumbcontainer">{(thumbsimage && thumbsimage.length > 0 ? thumbsimage : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                            <div className="dropzone_thumb">
                                                                                                <img
                                                                                                    src={fields?.value}
                                                                                                    className="dropzone_img"
                                                                                                />
                                                                                            </div>
                                                                                        </div> : '')}</div>
                                                                                    </div>

                                                                                    {fileRejectionItems}
                                                                                    <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                                </section>
                                                                            </>
                                                                            : (fields?.input == "rangepicker") ?
                                                                                <RangePicker onChange={(time, timeString) => {
                                                                                    console.log(timeString);
                                                                                    onChangeTime(time, timeString, index, fields?.name);
                                                                                }}
                                                                                    format={DateFormat}
                                                                                    className="form-control antformrange" />
                                                                                : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitBanner(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBanners;
