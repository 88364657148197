import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderList, ordersListById, ordersKitchenById } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from '../../utils/alert';
const namespace = "order";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    orderDatas: null,
    kitchenOrder: null,
    orderCount: 0
};

export const orderData = createAsyncThunk(
    `${namespace}/orderData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await orderList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const orderById = createAsyncThunk(
    `${namespace}/orderById`,
    async ({ orderID }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await ordersListById(orderID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


export const kitchenOrderDownload = createAsyncThunk(
    `${namespace}/kitchenOrderDownload`,
    async ({ orderID }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await ordersKitchenById(orderID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);



const orderSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearOrderLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [orderData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [orderData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
           
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log('orderlist',payloaddatas);
            state.orderCount = payloaddatas?.count;
            state.orderDatas = payloaddatas?.data;
        },
        [orderData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [orderById.pending](state) {
            console.log('testing');
            state.loading = API_STATUS.PENDING;
        },
        [orderById.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log('afterdecrypt',payloaddatas);
            state.orderDataById = payloaddatas?.order;
        },
        [orderById.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [kitchenOrderDownload.pending](state) {
            console.log('testing');
            state.loading = API_STATUS.PENDING;
        },
        [kitchenOrderDownload.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            //let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log('afterdecrypt',payload?.data);
            state.kitchenOrder = payload?.data;
        },
        [kitchenOrderDownload.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },

    },
});



export const { clearData, clearLoadingDatas, clearErrormsg } = orderSlice.actions;


export const orderSelector = (state) => state.order;

export default orderSlice.reducer;
