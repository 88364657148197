import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getColorList, addSavedcolor, addSelectedcolor } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from '../../utils/alert';
const namespace = "colorpicker";

const initialState = {
    loading: "initial",
    errorMessage: null,
    colorLists: null,
    addselectloading: "initial",
    addsavedloading: "initial"
};

export const colorList = createAsyncThunk(
    `${namespace}/colorList`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getColorList({});
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const addSavedColors = createAsyncThunk(
    `${namespace}/addSavedColors`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await addSavedcolor({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const addSelectedColors = createAsyncThunk(
    `${namespace}/addSelectedColors`,
    async ({ postDatas }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const data = await addSelectedcolor({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const colorPickerSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearColorLoading: (state) => {
            state.loading = "initial";
            state.addsavedloading = "initial"
            state.addselectloading = "initial"
        },
        clearSelectLoading: (state) => {
            state.addselectloading = "initial"
        },
        clearSavedLoading: (state) => {
            state.addsavedloading = "initial"
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [colorList.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [colorList.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            state.colorLists = payloaddatas?.result;

        },
        [colorList.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [addSavedColors.pending](state) {
            state.addsavedloading = API_STATUS.PENDING;
        },
        [addSavedColors.fulfilled](state, { payload }) {
            state.addsavedloading = API_STATUS.FULFILLED;
        },
        [addSavedColors.rejected](state, action) {
            state.addsavedloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [addSelectedColors.pending](state) {
            state.addselectloading = API_STATUS.PENDING;
        },
        [addSelectedColors.fulfilled](state, { payload }) {
            state.addselectloading = API_STATUS.FULFILLED;
        },
        [addSelectedColors.rejected](state, action) {
            state.addselectloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearColorLoading, clearSelectLoading, clearSavedLoading, clearErrormsg } = colorPickerSlice.actions;


export const colorPickerSelector = (state) => state.colorpicker;

export default colorPickerSlice.reducer;
