import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PageHeader from "../view/pageheader";
import TableCell from '@mui/material/TableCell';
import {
    getDisplayData,
    kitchenDisplaySelector,
    getDisplayReport
} from "../store/reducer/kitchendisplay";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { DateFormat } from "../services/config";
import { Divider, Space, Tag } from 'antd';
import {
    generalData,
    generalSelector,
} from "../store/reducer/general";
import {
    orderData,
    kitchenOrderDownload,
    orderSelector,
    clearLoadingDatas,
    clearData,
    clearErrormsg
} from "../store/reducer/order";
import TablePaginationActions from "../utils/pagination";
import { assestURL } from "../services/config";
import { toast } from 'react-toastify';
import 'dayjs/plugin/timezone'

function KitchenDisplay() {

    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState('')
    const [driveDatas, setdriveDatas] = useState([])
    const [scheduleDatas, setscheduleDatas] = useState([])
    const [displayReports, setdisplayReports] = useState('')
    const { orderDisplayDatas, driveThruList, pickupList, scheduleList, orderDisplayReport, loading, errorMessage } = useSelector(kitchenDisplaySelector);
    const { generalDatas } = useSelector(generalSelector);
    const { orderDatas, orderCount, kitchenOrder } = useSelector(orderSelector);
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(200);
    let [sortBy, setSortBy] = useState("id");
    let [activesortBy, setActiveSortBy] = useState("id");
    let [order, setOrder] = useState("DESC");
    let [searchQuery, setSearchQuery] = useState("");
    let [totalSales, setTotalSales] = useState("");

    console.log(orderDisplayReport, 'orderDisplayDatas');
    useEffect(() => {
        dispatch(generalData({}))
    }, [])

    const Reprint = (orderId) => {
        dispatch(kitchenOrderDownload({ orderID: orderId }));
    };

    useEffect(() => {
        if (driveThruList && driveThruList.length) {

            let vechicleDetails = driveThruList && driveThruList.map((item) => {
                let driveDetails = JSON.parse(item.orderMode_details)
                let vechicleData = `${driveDetails.vehicleModel}, ${driveDetails.vehicleColor}`
                return Object.assign({}, item, { orderMode_details: vechicleData });
            })
            setdriveDatas([...vechicleDetails])
        }

    }, [driveThruList])

    useEffect(() => {
        if (scheduleList && scheduleList.length) {
            let vechicleData;
            let scheduleDetails = scheduleList && scheduleList.map((item) => {
                if (item.order_mode == 1) {
                    let driveDetails = JSON.parse(item.orderMode_details)
                    vechicleData = `${driveDetails.vehicleModel}, ${driveDetails.vehicleColor}`

                } else {
                    vechicleData = item.orderMode_details
                }
                return Object.assign({}, item, { orderMode_details: vechicleData });
            })
            setscheduleDatas([...scheduleDetails])
        }

    }, [scheduleList])


    useEffect(() => {
        if (orderDisplayReport) {
            setdisplayReports(orderDisplayReport)
        }
    }, [orderDisplayReport])

    useEffect(() => {
        orderDispatch()

        // create a interval and get the data
        const myInterval = setInterval(() => {
            dispatch(getDisplayData({}))
            dispatch(getDisplayReport({}))
            orderDispatch()
        }, 5000);
        // clear out the interval when unmounting the component
        return () => clearInterval(myInterval);

    }, []);


    useEffect(() => {
        if (generalDatas) {
            let options = {
                timeZone: generalDatas[0].timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }

            // console.log(dayjs(new Date().toLocaleString("en-US")).format("MM/DD/YYYY hh:mm A"),'timenow');
            //  console.log(options,new Date().toLocaleString([], options),'timenow')
            //  console.log(dayjs(new Date().toLocaleString([])).tz(generalDatas[0].timezone),'timenow')
            setCurrentTime(moment().tz(generalDatas[0].timezone).format('MM-DD-YYYY hh:mm A'))


            const interval = setInterval(() => {

                let options = {
                    timeZone: generalDatas[0].timezone,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                }
                setCurrentTime(moment().tz(generalDatas[0].timezone).format('MM-DD-YYYY hh:mm:ss A'))

            }, 1000);
            return () => clearInterval(interval);
            //const timezone = 
        }
    }, [generalDatas])

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        orderDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        orderDispatch();
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        orderDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        orderDispatch();
    };

    const orderDispatch = () => {
        dispatch(
            orderData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        console.log(kitchenOrder, "kitchenOrder")
        if (kitchenOrder) {
            console.log(assestURL + kitchenOrder.pdfpath);
            if (kitchenOrder?.print_status == "failed")
                toast.error("Printing Failed");
            if (kitchenOrder?.print_status == "configure")
                toast.error("Printer not configured");
            if (kitchenOrder.pdfpath)
                window.open(assestURL + kitchenOrder.pdfpath, '_blank', 'noreferrer');
        }
    }, [kitchenOrder]);

    useEffect(() => {
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [errorMessage]);
    console.log(orderDatas, 'orderDatas===');

    return (
        <div>
            <div className="container-fluid">
                {/* <div className="row mt-3">
                    <h5>Kitchen Display</h5>
                </div> */}
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="row" style={{ marginBottom: '10px', marginTop: '5px' }}>
                            {/* <div className="col-md-4">
                                <button type="button" class="btn btn-danger " style={{ textAlign: 'left', float: 'left' }}><b>TOTAL ORDERS TODAY&nbsp;:&nbsp;{displayReports && displayReports.dailyReport[0].count ? displayReports.dailyReport[0].count : 0}</b></button>
                            </div> */}
                            <div className="col-md-4">
                                <h4>KITCHEN DISPLAY</h4>
                            </div>
                            <div className="col-md-3">
                                <div className="card overflowhidden number-chart">
                                    <div className="body" style={{ background: "radial-gradient(circle at -2% 57.5%,rgb(0, 102, 43) 0%, rgb(19, 170, 82) 90%)" }}>
                                        <div className="number">
                                            <h6 style={{ color: 'white' }}><i className="icon-layers"></i>&nbsp; TODAY'S SALES REPORT</h6>
                                            <table className="mt-3">
                                                <tr>
                                                    <td><h6 style={{ color: 'white' }}>Total Orders Count&nbsp;</h6></td>
                                                    <td><h6 style={{ color: 'white' }}>:</h6></td>
                                                    <td><h6 style={{ color: 'white' }}>&nbsp;{displayReports && displayReports.dailyReport[0].count ? displayReports.dailyReport[0].count : 0}</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 style={{ color: 'white' }}>Total Sales </h6></td>
                                                    <td><h6 style={{ color: 'white' }}>:</h6></td>
                                                    <td><h6 style={{ color: 'white' }}>&nbsp;<b>${displayReports && displayReports.dailyReport[0].total_amount ? displayReports.dailyReport[0].total_amount : 0}</b></h6></td>
                                                </tr>
                                            </table>
                                        </div>

                                        <small className="text-muted"></small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <button type="button" class="btn btn-light btn-lg" style={{ textAlign: 'right', float: 'right', fontSize: '16px' }}><b>{moment(currentTime).format('MM-DD-YYYY hh:mm A')}</b></button>
                            </div>
                        </div>
                        {/* <div style={{ marginBottom: '30px', color: 'black' }}> <h6 style={{ textAlign: 'center', float: 'left' }}><b>TOTAL ORDERS</b>&nbsp;:&nbsp;{displayReports && displayReports.dailyReport[0].count}</h6> <h6 style={{ textAlign: 'right' }}>{currentTime}</h6></div> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <div className="col-md-8">
                                        <h6>TOTAL ORDERS - <button type="button" class="btn btn-success">Count : <b>{orderCount ? orderCount : 0}</b></button></h6>
                                    </div>
                                    <TableContainer
                                    // sx={{
                                    //     height: 1000
                                    // }}
                                    >
                                        <Table
                                            // sx={{
                                            //     height: "max-content"
                                            // }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order ID
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Customer Name
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Status
                                                        </TableSortLabel>
                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Total ($)
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Status
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    // orderDisplayDatas && orderDisplayDatas.map((row) => (
                                                    orderDatas && orderDatas.map((row) => (
                                                        <StyledTableRow>
                                                            <StyledTableCell align="">
                                                                <b>{row.id}</b>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {(row.customer_id != null) ? row?.customer?.first_name + " " + row?.customer?.last_name : ""}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_total}
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell align="">
                                                                <button className="btn btn-outline-secondary" onClick={() => { Reprint(row.id) }}>
                                                                    <i className="icon-printer"></i>
                                                                </button>
                                                            </StyledTableCell> */}
                                                            <StyledTableCell align="">
                                                                {row.order_status == 1 ? "Confirmed" : row.order_status == 2 ? "Payment Failed" : row.order_status == 3 ? "Payment Failed" : row.order_status == 4 ? 'CheckIn pending MobileDevice ' : row.order_status == 5 ? 'Delivered' : ''}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[200, 225, 250]}
                                                        colSpan={8}
                                                        count={orderCount}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: {
                                                                "aria-label": "rows per page",
                                                            },
                                                            native: true,
                                                        }}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <div className="col-md-8">
                                        <h6>TODAY'S ORDERS - <button type="button" class="btn btn-success">Total Active Orders : <b>{orderDisplayDatas && orderDisplayDatas.length ? orderDisplayDatas.length : 0}</b></button></h6>
                                    </div>
                                    <TableContainer
                                    // sx={{
                                    //     height: 980
                                    // }}
                                    >
                                        <Table
                                            // sx={{
                                            //     height: "max-content"
                                            // }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order ID
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Customer Name
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Mode
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Total ($)
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Reprint
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    orderDisplayDatas && orderDisplayDatas.map((row) => (
                                                        <StyledTableRow>
                                                            <StyledTableCell align="">
                                                                <b>{row.id}</b>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_mode == 1 ? "Drive-Thru" : "Pickup Order"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_total}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <button className="btn btn-outline-secondary" onClick={() => { Reprint(row.id) }}>
                                                                    <i className="icon-printer"></i>
                                                                </button>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            {/* <div className="col-md-7">
                                <div className="row mb-3">
                                    <div className="card p-2">
                                        <div className="col-md-8">
                                            <h6>Drive-Thru Orders - <button type="button" class="btn btn-success">Active Orders : <b>{driveDatas && driveDatas.length ? driveDatas.length : 0}</b></button></h6>
                                        </div>
                                        <div className="col-md-12">
                                            <TableContainer
                                                sx={{
                                                    height: 250
                                                }}
                                            >
                                                <Table
                                                    sx={{
                                                        height: "max-content"
                                                    }}
                                                    stickyHeader
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order ID
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Customer Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Vehicle Details
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Total ($)
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            driveDatas && driveDatas.map((row) => (
                                                                < StyledTableRow >
                                                                    <StyledTableCell align="">
                                                                        <b>{row.id}</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.orderMode_details}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_total}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                            )
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="card p-2">
                                        <div className="col-md-8">
                                            <h6>Front Window Orders - <button type="button" class="btn btn-success">Active Orders : <b>{pickupList && pickupList.length ? pickupList.length : 0}</b></button></h6>
                                        </div>
                                        <div className="col-md-12">
                                            <TableContainer
                                                sx={{
                                                    height: 250
                                                }}
                                            >
                                                <Table
                                                    sx={{
                                                        height: "max-content"
                                                    }}
                                                    stickyHeader
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order ID
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Customer Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Total ($)
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            pickupList && pickupList.map((row) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="">
                                                                        <b>{row.id}</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_total}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="card p-2">
                                        <div className="col-md-12">
                                            <h6>Scheduled Pickup Orders - <button type="button" class="btn btn-success">Active Orders : <b>{scheduleList && scheduleList.length ? scheduleList.length : 0}</b></button></h6>
                                            <TableContainer
                                                sx={{
                                                    height: 250
                                                }}
                                            >
                                                <Table
                                                    sx={{
                                                        height: "max-content"
                                                    }}
                                                    stickyHeader
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order ID
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Customer Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Schedule Time
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Mode
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    hideSortIcon={true}
                                                                >
                                                                    Order Total ($)
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            scheduleDatas && scheduleDatas.map((row) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="">
                                                                        <b>{row.id}</b>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row.schedule_pickup == '1') ? <p><i class="fa fa-clock-o"></i>&nbsp;<b>{row.schedulepickup_time}</b></p> : '-'}                                                                </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_mode == 2 ? 'Pickup Order' : row.order_mode == 1 ? <>Drive-Thru<br />({row.orderMode_details})</> : ''}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.order_total}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div> */}
                            {/* <div className="col-md-4">
                                <div className="col-md-12">
                                    <h6>TOTAL</h6>
                                    <table>
                                        <tr>
                                            <td><b>Daily -</b> {displayReports && displayReports.dailyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Weekly -</b>{displayReports && displayReports.weeklyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Monthly -</b>{displayReports && displayReports.monthlyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Quaterly -</b>{displayReports && displayReports.quaterlyReport[0].count}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Yearly -</b>{displayReports && displayReports.yearlyReport[0].count}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default KitchenDisplay