import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getKitchenDisplay, getKitchenDisplayReport, getkitchenDispatch, updateDelivered } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from '../../utils/alert';
const namespace = "kitchendisplay";

const initialState = {
    loading: "initial",
    errorMessage: null,
    orderDisplayDatas: null,
    driveThruList: null,
    pickupList: null,
    scheduleList: null,
    orderDisplayReport: null,
    orderCount: 0,
    pickupDispatchList: null,
    driveDispatchList: null,
    delieverdOrderID: null,
    dispatchloading: "initial",
    deliveryStatusloading: "initial",
    reportsloading: "initial",
};

export const getDisplayData = createAsyncThunk(
    `${namespace}/getDisplayData`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getKitchenDisplay();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getDisplayReport = createAsyncThunk(
    `${namespace}/getDisplayReport`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getKitchenDisplayReport();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

//Kitchen Dispatch

export const getdispatchList = createAsyncThunk(
    `${namespace}/getdispatchList`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await getkitchenDispatch();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updateDeliverStatus = createAsyncThunk(
    `${namespace}/updateDeliverStatus`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await updateDelivered({ payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const kitchenDisplaySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearOrderLoadingDatas: (state) => {
            state.loading = "initial";
        },
        clearReportsLoadingDatas: (state) => {
            state.loading = "initial";
        },
        cleardisptachLoadingDatas: (state) => {
            state.deliveryStatusloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [getDisplayData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [getDisplayData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log('orderlist', payloaddatas);
            state.orderDisplayDatas = payloaddatas?.totalOrder;
            state.scheduleList = payloaddatas?.schedulePickup;
            state.driveThruList = payloaddatas?.driveThru;
            state.pickupList = payloaddatas?.pickupOrder;
        },
        [getDisplayData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [getDisplayReport.pending](state) {
            state.reportsloading = API_STATUS.PENDING;
        },
        [getDisplayReport.fulfilled](state, { payload }) {
            state.reportsloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            state.orderDisplayReport = payloaddatas;
        },
        [getDisplayReport.rejected](state, action) {
            state.reportsloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },

        //Kitchen Disptach
        [getdispatchList.pending](state) {
            state.dispatchloading = API_STATUS.PENDING;
        },
        [getdispatchList.fulfilled](state, { payload }) {
            state.dispatchloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log('orderlist', payloaddatas);
            state.driveDispatchList = payloaddatas?.driveThru;
            state.pickupDispatchList = payloaddatas?.pickupOrder;
        },
        [getdispatchList.rejected](state, action) {
            state.dispatchloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },

        //Update Delivery
        [updateDeliverStatus.pending](state) {
            state.deliveryStatusloading = API_STATUS.PENDING;
        },
        [updateDeliverStatus.fulfilled](state, { payload }) {
            state.deliveryStatusloading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            state.delieverdOrderID = payload?.data?.datas
        },
        [updateDeliverStatus.rejected](state, action) {
            state.deliveryStatusloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, cleardisptachLoadingDatas, clearReportsLoadingDatas, clearErrormsg } = kitchenDisplaySlice.actions;


export const kitchenDisplaySelector = (state) => state.kitchendisplay;

export default kitchenDisplaySlice.reducer;
