import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../view/pageheader";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { Button, Space } from "antd";
import {
  colorList,
  addSavedColors,
  addSelectedColors,
  clearErrormsg,
  deleteColor,
  colorPickerSelector,
} from "../../store/reducer/colorpicker";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/constants";
import { GetColorName } from "hex-color-to-color-name";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { EvpKDF } from "crypto-js";

function Colorpallete() {
  let navigate = useNavigate();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const simpleValidator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const [color, setColor] = useColor("hex", "#121212");
  const [addcolor, setaddcolor] = useState({
    colorCode: "#121212",
    colorName: "",
  });
  const [savedColorList, setsavedColorList] = useState([]);
  const [selectedColorList, setselectedColorList] = useState([]);
  const [fixedSelected, setfixedSelected] = useState([]);
  const [deleteTrue, setdeleteTrue] = useState(false);
  const [saveColors, setsaveColors] = useState([]);
  const {
    loading,
    errorMessage,
    colorLists,
    addselectloading,
    addsavedloading,
  } = useSelector(colorPickerSelector);
  const [showvalidator, setShowvalidator] = useState(false);

  console.log(savedColorList, 'savedColorList');
  console.log(selectedColorList, 'selectedColorList');
  console.log(deleteTrue, 'deleteTrue');

  useEffect(() => {
    dispatch(colorList({}));
  }, []);

  useEffect(() => {
    simpleValidator.current.showMessages();
  }, []);

  useEffect(() => {
    if (colorLists) {
      let colorData = colorLists[0].saved_colors;
      let parseData = [];
      if (colorData) {
        parseData = JSON.parse(colorData);
      }
      setsavedColorList(parseData);

      let selectedColor = colorLists[0].selected_colors;
      let parseDatas = [];
      if (selectedColor) {
        parseDatas = JSON.parse(selectedColor);
      }

      setselectedColorList(parseDatas);
      setfixedSelected(parseDatas);
    }
  }, [colorLists]);

  const OnsavedColors = (colors, checkType) => {
    if (checkType == true) {
      let savedColorArr = selectedColorList;
      savedColorArr.push(colors);
      // let Arraydata = new Set(savedColorArr)
      // let postData = [...Arraydata]
      // console.log(postData, 'savedColorArr--array==');

      setselectedColorList([...savedColorArr]);
      // dispatch(addSelectedColors({ postData }))
    } else {
      let savedColorArr = selectedColorList;
      let removeData = savedColorArr.filter((item) => {
        return item.colorCode != colors.colorCode;
      });
      // let postData = removeData
      setselectedColorList([...removeData]);
      // dispatch(addSelectedColors({ postData }))
    }
  };

  const setColorChange = (data) => {
    setColor(data);
    // let existingColor = addcolorArray
    // console.log(existingColor, 'existingColor==');
    // existingColor.push(data.hex)
    // console.log(existingColor, 'existingColor==array');
    // let Arraydata = new Set(existingColor)
    // setcolorArray([...Arraydata])
    setaddcolor({ ...addcolor, colorCode: data.hex });
  };

  const onAddcolor = () => {
    if (simpleValidator.current.allValid()) {
      let checkAlreadyColorExist = savedColorList.some((exist) => {
        return addcolor.colorCode == exist.colorCode;
      });
      let checkAlreadyNameExist = savedColorList.some((exist) => {
        return (
          addcolor.colorName.toUpperCase() == exist.colorName.toUpperCase()
        );
      });

      console.log(checkAlreadyColorExist);
      if (checkAlreadyColorExist && checkAlreadyNameExist) {
        toast.error("This Color Already Exist!");
      } else if (checkAlreadyColorExist) {
        toast.error("This Color Already Exist!");
      } else if (checkAlreadyNameExist) {
        toast.error("This Color Already Exist!");
      } else {
        let payloadData = savedColorList;
        let Data = payloadData.concat(addcolor);
        let postData = JSON.stringify(Data);
        dispatch(addSavedColors({ postData }));
      }
    } else {
      setShowvalidator(true);
    }
  };

  const onSelectcolor = () => {
    if (selectedColorList.length) {
      let postDatas = JSON.stringify(selectedColorList);
      if (selectedColorList.length <= 15) {
        dispatch(addSelectedColors({ postDatas }));
      } else {
        toast.dismiss();
        toast.error("Choose only 15 colors");
      }
    } else {
      toast.dismiss();
      toast.error("Choose Colors!");
    }
  };

  const OnselectAll = (checkType) => {
    if (checkType == true) {
      // let colorList = savedColorList
      // let removeColor = colorList.filter((item) => item.colorCode != color.colorCode)
      // console.log(removeColor, savedColorList, 'removeColor===');
      // let postData = removeColor
      // dispatch(addSelectedColors({ removeColor }))
      let allColors = savedColorList;
      setselectedColorList([...allColors]);
    } else {
      setselectedColorList([]);
    }
  };

  const onDeleteColor = () => {
    if (savedColorList && savedColorList.length > 0) {
      let alreadySavedColors = savedColorList;
      let alreadySelectedColors = fixedSelected;
      let data = selectedColorList;

      console.log(alreadySelectedColors.length, data, "alreadySelectedColors===");
      if (data.length > 0) {
        MySwal.fire({
          title: "Are You Sure to Delete Color?",
          icon: "warning",
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */

          if (result.isConfirmed) {

            const NewsaveList = alreadySavedColors.filter(
              (item) => !data.some((item2) => item.colorCode === item2.colorCode)
            );
            const NewSelectList = alreadySelectedColors.filter(
              (item) => !data.some((item2) => item.colorCode === item2.colorCode)
            );

            // let data = alreadySelectedColors.filter((item) => { return item != })

            let postData = JSON.stringify(NewsaveList);
            let postDatas = JSON.stringify(NewSelectList);
            console.log(postData, postDatas, "posDataaa==");
            dispatch(addSavedColors({ postData }));
            // dispatch(addSelectedColors({ postDatas }));
            setdeleteTrue(true);
          }
        });
      }
    }
    else {
      toast.error('Please Add Colors!')
    }
  };



  const resetFields = () => {
    setShowvalidator(false);
  };

  useEffect(() => {
    if (addsavedloading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success(
        deleteTrue
          ? "Colors Deleted Succesfully!"
          : "Colors Added Successfully!"
      );
      navigate("/colorpicker");
      dispatch(colorList({}));
      setShowvalidator(false);
    }

    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [addsavedloading, errorMessage]);

  useEffect(() => {
    if (addselectloading === API_STATUS.FULFILLED) {
      toast.dismiss();
      savedColorList && savedColorList.length > 0 ?
        toast.success('Colors Selected Successfully!') :
        toast.error('Please Add Colors to Delete!')
      setdeleteTrue(false);
      // dispatch(colorList({}));
    }
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }

      dispatch(clearErrormsg());
    }
  }, [addselectloading, errorMessage])

  return (
    <div>
      <div className="container-fluid">
        <PageHeader
          HeaderText="Color Picker"
          Breadcrumb={[{ name: "Color Picker" }]}
        />
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="header">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Color Picker for Drive-Thru</h2>
                  </div>
                </div>
              </div>

              <div className={"body"}>
                {/* <form className="ng-untouched ng-dirty ng-invalid"> */}
                <div className="row">
                  {location.pathname == "/colorpicker" ? (
                    <>
                      <div className="col-md-12">
                        <p style={{ float: "left" }}>
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;&nbsp;You can Choose maximum 15 colors to
                          Display in Check-in
                        </p>
                        <Link
                          to="/addcolor"
                          onClick={() => {
                            setaddcolor({
                              ...addcolor,
                              colorCode: "#121212",
                              colorName: "",
                            });
                            setdeleteTrue(false)
                          }}
                          className="btn btn-primary mr-3 float-right"
                        >
                          {" "}
                          Add New Colors
                        </Link>
                      </div>
                      <div className="col-md-12">
                        <label>Saved Colors</label><br />
                        {/* <div className="col-md-12"> */}
                        {/* <p>
                          <input
                            type="checkbox"
                            style={{ zoom: "1.5" }}
                            onClick={(e) => {
                              OnselectAll(e.target.checked);
                            }}
                          ></input><label className="mb-2" htmlFor="select_all">Select All</label>
                        </p> */}
                        <span className="d-flex mb-3"><input type="checkbox" checked={savedColorList.length === selectedColorList.length ? true : false} className="me-2" style={{ zoom: "1.5" }}
                          onClick={(e) => {
                            OnselectAll(e.target.checked);
                          }} /><b>Select All</b></span>
                        {/* </div> */}
                        <div className="d-block">
                          {savedColorList && savedColorList.length
                            ? savedColorList.map((item, i) => {
                              // console.log(item, 'item00==');
                              return (
                                // <div class="card" style={{ width: "5rem" }}>
                                //     <div class="card-body" style={{ background: `${item}` }}>
                                //     </div>
                                // </div>
                                // <p>{item}</p>
                                // <Space size={"small"}>

                                <div className="me-5 d-inline-block">
                                  <div className="d-flex">
                                    <input
                                      className="me-1"
                                      type="checkbox"
                                      id={i}
                                      style={{ zoom: "1.5" }}
                                      checked={selectedColorList.some(
                                        (exist) => {
                                          return (
                                            item.colorCode == exist.colorCode
                                          );
                                        }
                                      )}
                                      onClick={(e) => {
                                        OnsavedColors(item, e.target.checked);
                                      }}
                                    ></input>

                                    <label
                                      className="m-0 p-0"
                                      for={i}
                                      style={{
                                        background: `${item.colorCode}`,
                                        borderRadius: "8px",
                                        height: "65px",
                                        width: "65px",
                                        border: "2px solid black",
                                      }}
                                    ></label>
                                  </div>
                                  <label
                                    style={{
                                      marginLeft: "30px",
                                      marginTop: "5px",
                                    }}
                                    for={i}
                                    className="d-block p-0 "
                                  >
                                    {item.colorName}
                                  </label>
                                  {/* <i class="fa fa-remove" onClick={() => { OnremoveColor(item) }}></i> */}
                                </div>
                              );
                            })
                            : ""}
                        </div>
                      </div>
                      <button
                        class="offset-md-3 col-md-2 mt-3 btn btn-danger"
                        onClick={() => {
                          onDeleteColor();
                        }}
                      >
                        Delete
                      </button>
                      <button
                        id="submit_btn"
                        class="offset-md-1 col-md-2 mt-3 btn btn-success"
                        onClick={() => {
                          onSelectcolor();
                        }}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    ""
                  )}

                  {location.pathname == "/addcolor" ? (
                    <>
                      <div className="col-md-12">
                        <Link
                          to="/colorpicker"
                          className="btn btn-primary mr-3 float-right"
                          onClick={(e) => {
                            resetFields();
                          }}
                        >
                          Cancel
                        </Link>
                      </div>
                      {/* <div className="col-md-12"> */}
                      <div className="col-md-7">
                        <div className="form-group">
                          <label>Choose Color</label>
                          <br />
                          <ColorPicker
                            width={400}
                            height={225}
                            color={color}
                            onChange={(e) => {
                              setColorChange(e);
                            }}
                            hideHSV
                            dark
                          />
                        </div>
                      </div>
                      <div className="col-md-5" style={{ marginTop: "100px" }}>
                        <div className="form-group">
                          <Button
                            style={{
                              background: `${addcolor.colorCode}`,
                              height: "150px",
                              width: "150px",
                              marginRight: "12px",
                              borderColor: "black",
                              border: "2px solid black",
                            }}
                          ></Button>
                          &nbsp;
                        </div>
                        <div className="form-group">
                          <label>
                            Enter Color Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            style={{ width: "250px", height: "35px" }}
                            placeholder="Black"
                            onChange={(e) => {
                              setaddcolor({
                                ...addcolor,
                                colorName: e.target.value,
                              });
                            }}
                          />
                          &nbsp;
                        </div>
                        {simpleValidator.current.message(
                          "first_name",
                          addcolor.colorName,
                          "required|alpha_space",
                          {
                            className: `invalid-feedback
                                                        ${showvalidator
                                ? "show"
                                : "hide"
                              }`,
                            messages: {
                              required: "Please Enter Color Name",
                              alpha_space: "Please Enter Valid Color Name",
                            },
                          }
                        )}
                      </div>
                      {/* </div> */}
                      <button
                        id="submit_btn"
                        class="offset-md-5 col-md-2 btn btn-success"
                        onClick={() => {
                          onAddcolor();
                        }}
                      >
                        Submit
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Colorpallete;
